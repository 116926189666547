
import type * as L from 'leaflet'
import { debounce } from 'lodash'
import { GeoCircle } from '~/utility/geo/GeoLocation'
import { GeoBounds } from '~/utility/geo/bounds'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { logger } from '../../../../utility/logger'
import { Marker } from './types'

@Component({
  components: {
    'leaflet-map': () => {
      if (process.client) {
        return import('./non-autoload-components/LeafletMap.vue')
      } else {
        return
      }
    },
  },
})
export default class PuMap extends Vue {
  @Prop({ required: true })
    markers: Marker[]

  @Prop({ required: false, default: 8 })
    initialZoom: number

  @Prop({ required: false })
    initialBounds?: GeoBounds

  @Prop({ required: false, default: null })
    circle: GeoCircle | null

  @Prop({ required: false, default: true })
    enablePopup: boolean

  @Prop({ required: false })
    visibleMarker: string

  @Prop({ required: false })
    centre?: string

  @Prop({ default: false })
    useClustering: boolean

  @Prop({ default: false })
    showGeolocationIcon: boolean

  @Prop({ default: false })
    zoomControl: boolean

  @Prop({ default: false })
    scrollWheelZoom: boolean

  @Prop({ default: () => [] })
    seenCampsites: string[]

  @Prop({ default: undefined })
    flyTo?: boolean

  get mapBounds(): L.LatLngBoundsLiteral | null {
    logger('PuMap.mapBounds', JSON.stringify(this.initialBounds))
    return this.initialBounds
      ? [
        [this.initialBounds.northEast.lat, this.initialBounds.northEast.lng],
        [this.initialBounds.southWest.lat, this.initialBounds.southWest.lng],
      ]
      : null
  }

  boundsChanged(bounds: GeoBounds) {
    logger('PuMap.boundsChanged', JSON.stringify(bounds))
    this.$bubble('boundsChange', bounds)
  }

  debouncedBoundsChanged = debounce((event) => this.boundsChanged(event), 2000)

  fitBounds(bounds) {
    logger('PuMap.fitBounds', JSON.stringify(bounds))
    const leafletMap = this.$refs.leafletMap
    if (leafletMap) {
      leafletMap.fitBounds(bounds)
    } else {
      setTimeout(() => {
        this.fitBounds(bounds)
      }, 100)
    }
  }

  getBounds() {
    const leafletMap = this.$refs.leafletMap
    if (leafletMap) {
      return leafletMap.getBounds()
    }
  }
}
