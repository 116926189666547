
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { getPointFromStringOrDefault } from '~/utility/geo/latLng'
import googleMap from '~/utility/google-map'

@Component
export default class DirectionsButton extends Vue {
  @Prop({ required: true })
    point: string

  @Prop({ required: true })
    name: string

  @Prop({ required: true })
    address: string

  get directionsMapLink() {
    const point = getPointFromStringOrDefault(this.point)
    return googleMap.googleMapLink(
      point.lat,
      point.lng,
      this.name,
      this.address,
    )
  }
}
