
import { useCurrencyStore, useSeenCampsitesStore, usePinia } from '#imports'
import { formatPrice } from '~/utility/formatCurrency'
import { getPointFromStringOrDefault } from '~/utility/geo/latLng'
import { priceToMoneyType } from '~/utility/money'
import { Component, Prop } from '~/utility/pu-class-decorator'
import { logger } from '../../../../utility/logger'
import { MapCampsite } from '../../domain/MapsCampsite'
import { PuMapProps } from '../pu-map/PuMapProps'
import { IconMarker, MarkerType, PriceMarker } from '../pu-map/types'
import CampsitesMapBase from './CampsitesMapBase'
import { CampsitesMapProps } from './CampsitesMapProps'

@Component({
  setup() {
    const currencyStore = useCurrencyStore(usePinia())
    const seenCampsitesStore = useSeenCampsitesStore(usePinia())
    return {
      currencyStore,
      seenCampsitesStore,
    }
  },
})
export default class CampsitesMap
  extends CampsitesMapBase
  implements CampsitesMapProps
{
  currencyStore: ReturnType<typeof useCurrencyStore>
  seenCampsitesStore: ReturnType<typeof useSeenCampsitesStore>

  @Prop({ required: true })
    campsites: MapCampsite[]

  @Prop({ default: MarkerType.icon })
    markersType: MarkerType

  @Prop({ default: undefined })
    flyTo?: boolean

  @Prop()
    showSeenCampsites: boolean

  get markers() {
    logger('CampsitesMap.markers', this.campsites)
    return this.campsites.map((campsite) => {
      if (!campsite.price || this.markersType === MarkerType.icon) {
        return this.getIconMarker(campsite)
      }
      return this.getPriceMarker(campsite)
    })
  }

  private getMarkerBase(campsite: MapCampsite) {
    const { lat, lng } = campsite.position
      ? campsite.position
      : getPointFromStringOrDefault(campsite.point)
    return {
      campsiteId: campsite.id,
      data: campsite,
      name: campsite.name,
      position: { lat, lng },
      slug: campsite.slug,
      seenMarkers: this.showSeenCampsites
        ? this.seenCampsitesStore.seenCampsites
        : undefined,
    }
  }

  private getIconMarker(campsite: MapCampsite): IconMarker {
    return {
      ...this.getMarkerBase(campsite),
      type: MarkerType.icon,
    }
  }

  private getPriceMarker(campsite: MapCampsite): PriceMarker {
    const convertedPrice = this.currencyStore.convert(
      priceToMoneyType(campsite.price),
      this.$i18n.current.currency,
    )
    return {
      ...this.getMarkerBase(campsite),
      type: MarkerType.price,
      price: convertedPrice,
    }
  }

  get puMapProps(): PuMapProps {
    const centre =
      this.markers.length === 1
        ? this.markers[0]?.position
        : this.centre || this.circle
    return {
      initialZoom: this.initialZoom,
      centre,
      markers: this.markers,
      initialBounds: this.initialBounds,
      circle: this.circle,
      useClustering: this.useClustering,
      zoomControl: this.zoomControl,
    }
  }

  markerClicked(marker) {
    this.seenCampsitesStore.add(marker.slug)
    this.$emit('marker-clicked', marker)
  }
}
