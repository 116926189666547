import { render, staticRenderFns } from "./CampsiteAddressAndMap.vue?vue&type=template&id=48f0d7b0&scoped=true"
import script from "./CampsiteAddressAndMap.vue?vue&type=script&lang=ts"
export * from "./CampsiteAddressAndMap.vue?vue&type=script&lang=ts"
import style0 from "./CampsiteAddressAndMap.vue?vue&type=style&index=0&id=48f0d7b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f0d7b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DirectionsButton: require('/app/apps/campsites/components/DirectionsButton.vue').default,CampsitesMap: require('/app/apps/maps/components/campsites/CampsitesMap.vue').default})
