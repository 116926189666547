export default {
  /*
  * Generates google maps link with or without starting point.
  * Starting point (startingAddress) is optional.
   */
  googleMapLink: (
    destinationLat: number,
    destinationLng: number,
    destinationName: string,
    startingAddress = '',
  ): string => {
    const link = `https://maps.google.com/maps`
    const parts: string[] = []
    if (startingAddress) {
      parts.push(`saddr=${startingAddress}`)
    }
    if (destinationLat && destinationLng && destinationName) {
      parts.push(`daddr=${destinationLat},${destinationLng} (${destinationName.split(' ').join('+')})`)
    }

    if (parts.length > 0) {
      return encodeURI(`${link}?${parts.join('&')}`)
    }

    return encodeURI(link)
  },
}
