
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { MapCampsite } from '~/apps/maps/domain/MapsCampsite'
import { CampsitesMapProps } from '../../maps/components/campsites/CampsitesMapProps'

@Component
export default class CampsiteAddressAndMap extends Vue {
  @Prop({ required: true })
    campsite: MapCampsite

  @Prop({ required: true })
    campsiteAddress: string

  @Prop({ default: '' })
    userAddress: string

  get formatAddress() {
    if (this.campsiteAddress) {
      return `${this.campsite.name}, ${this.campsiteAddress}`
        .split(', ')
        .map((el) => `<p>${el}</p>`)
        .join('')
    }
    return ''
  }

  get mapProps(): CampsitesMapProps {
    return {
      campsites: [this.campsite],
      isCampsiteDetails: true,
      zoomControl: !!this.$isDesktop,
    }
  }

  mapClick() {
    let mapModalButton = document.querySelector('.map-link') as HTMLElement
    if (mapModalButton) mapModalButton.click()
  }
}
