import { SimpleMoney } from '~/utility/money'
import { LatLng } from '../../types'

export enum MarkerType {
  icon = 'icon',
  price = 'price',
}

export interface PuMarker {
  slug: string
  name: string
  // position: LatLngLiteral
  position: any
  isSeen?: boolean
  data?: any
}

export interface IconMarker extends PuMarker {
  type: MarkerType.icon
}

export interface PriceMarker extends PuMarker {
  price: string | SimpleMoney | undefined
  type: MarkerType.price
}

export type Marker = IconMarker | PriceMarker

export interface MarkerIcon {
  url: string
  size: number[]
  anchor: number[]
  popupAnchor: number[]
}

interface MarkerBase {
  campsiteId: string
  slug: string
  name: string
  position: LatLng
  data?: any
}

export interface BaseIconMarker extends MarkerBase {
  icon: MarkerIcon
  type: MarkerType.icon
  seenMarkers: string[]
}

export interface BasePriceMarker extends MarkerBase {
  price: string | undefined
  type: MarkerType.price
  seenMarkers: string[]
}

export type BaseMarker = BaseIconMarker | BasePriceMarker
