import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { GeoBounds } from '~/utility/geo/bounds'
import { GeoCircle, LatLng } from '~/utility/geo/GeoLocation'

@Component
export default class CampsitesMapBase extends Vue {
  // Some features like zoom level/search as you move are disabled on campsite
  // details
  @Prop({ default: false })
    isCampsiteDetails: boolean

  @Prop({ required: false, default: null })
    circle: GeoCircle | null

  @Prop({ required: false })
    initialBounds?: GeoBounds

  @Prop({ required: false })
    visibleMarker: string

  @Prop({ required: false })
    centre?: string

  @Prop({ default: false })
    useClustering: boolean

  @Prop({ default: false })
    zoomControl: boolean

  get initialZoom() {
    return this.isCampsiteDetails ? 13 : (10 as number)
  }
}
